/* Reset de estilos */
html, body, #root {
  margin: 0;
  padding: 0;
}

/* Estilo global da aplicação */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color:rgb(255, 255, 255),
}

.content {
  display: flex;
  flex: 1;
  padding: 20px;
  justify-content: center;
}



/* Estilos da barra lateral */
.sidebar {
  background-color: #8aef37;
  color: #fff;
  width: 200px;
  padding: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin-bottom: 10px;
}

.sidebar a {
  color: #fff;
  text-decoration: none;
}

/* Estilos do rodapé */
.footer {
  background-color: #2c3e50;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.footer p {
  margin: 0;
}
.button{
  background-color: #008000;
  color: #E8CA74;
}
.backbutton{
  color: #008800!important;
}
.content{
  background-color:white;
}
h4{
  color:#008800;
}
h3{
  color:#008800;
}
.MuiMenu-paper {
  background-color: rgb(255, 255, 255)!important;
}
.MuiFormLabel-root.Mui-focused {
  color: #008800!important;
}
.MuiInputBase-root {
  color:  #008800!important;
}
.text{
  padding: 5px;
  color:#008800!important;
}
.MuiIconButton-colorPrimary {
    color: #008800!important;
}
.title{
  font-size: 22px!important;
  color:#008800;
  margin: 15px!important;
}
.iconEdit{
  color:#000!important;
}
.iconDetail{
  color:#008800!important;
  font-size: 14;
}
.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.2rem!important;
  user-select: none;
}
.iconDelete {
  color:'red';
}
.MuiTooltip-tooltip {
  color: #E8CA74!important;
  background-color: #008000!important;
  padding: 4px!important;
  font-size: 0.675rem!important;
  max-width: 400px;
  word-wrap: break-word;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.4em;
  border-radius: 4px;
  margin:2px !important;
}

.listContainer{
   
  max-height: calc(100vh - 200px);
  width: 800px!important;
  max-width:80vw;
  overflow: auto;
  
 
  
}

.listContainer::-webkit-scrollbar {
  width: 10px;
}
.listContainer::-webkit-scrollbar-track {
  background-color: #e8ca74;
}
.listContainer::-webkit-scrollbar-thumb {
  background-color: green!important;
}
.listContainer::-webkit-scrollbar-thumb:hover {
  background-color: darkgreen!important;
}

/* tabpanel */
.css-19kzrtu {
  margin: 0 auto!important;
}

.css-uym98a-MuiImageList-root {
  margin: 0 auto;
}

.file-upload {
  text-align: center;
  border: 3px dashed rgb(210, 227, 244);
  margin: 1.2rem;
  padding: 1.2rem;
  position: relative;
  cursor: pointer;
}
.file-upload p {
  font-size: 0.87rem;
  margin-top: 10px;
  color: #bbcada;
}
.file-upload input {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}