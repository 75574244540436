.header {
    background-color: #006c00;
    color: #f4cb6f;
    font-family: 'Roboto', sans-serif;
    
    
    /* Estilos adicionais */
  }
  /* Estilos do cabeçalho */
.logo{
 
      height:50px;
    
    
}
.box {
  position: absolute;
    top: 0;
    width: 200px;
    height: 50px;
    background: #006c00;
    left: 40px;
}

.header h1 {
  margin: 0;
}
.typography{
  line-height: 0!important;
}
.user-info {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 10px 0 0;
}
.user-info .user-letter {
  background: 'transparent';
  color: #fff;
  border-radius: 25px;
  justify-content: center;
  display: flex;
  align-items: center;
  
  margin-right: 10px;
  /* border: 1px solid #f4cb6f; */
}
.user-info .user-name {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
}
.user-info .user-role {
  display: block;
  color: #f7f7f3;
  font-size: 12px;
}